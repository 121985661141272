




import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { Rules } from '@/components/form/types'

@Component({})
export default class TextArea extends Vue {
    @Prop() readonly name!: string
    @Prop() readonly label!: string
    @Prop() readonly rules!: Rules
    @Prop() readonly hint!: string
    @VModel() text!: string
}
