




import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { Rules } from '@/components/form/types'

@Component({})
export default class Select extends Vue {
    @Prop({ default: false }) outlined!: boolean
    @Prop({}) testId!: string
    @Prop({ required: false }) readonly label!: string
    @Prop({ default: () => [] }) rules!: Rules
    @Prop({ default: () => [] }) items!: Array<{
        text: string
        value: string
    }>
    @VModel({ type: String }) select?: string
}
