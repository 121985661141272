












import { Component, Vue, Prop, VModel } from 'vue-property-decorator'
import { Rules } from '@/components/form/types'

@Component({})
export default class TextInput extends Vue {
    @Prop() readonly type!: 'text' | 'password'
    @Prop({ default: false }) outlined!: boolean
    @Prop({ required: false }) readonly label!: string
    @Prop({ default: () => [] }) errorMessages!: string[]
    @Prop({ default: () => [] }) rules!: Rules
    @VModel({ type: String }) text!: string
}
