import { render, staticRenderFns } from "./boulder-canvas.vue?vue&type=template&id=5e2b206e&scoped=true&"
import script from "./boulder-canvas.vue?vue&type=script&lang=ts&"
export * from "./boulder-canvas.vue?vue&type=script&lang=ts&"
import style0 from "./boulder-canvas.vue?vue&type=style&index=0&id=5e2b206e&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2b206e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtnToggle,VIcon,VSpacer,VToolbar})
