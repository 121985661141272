





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/infrastructure/ui/button.vue'
import { Hold } from '@/domain/boulder/hold'
import ImageLoader from '@/components/image-loader/image-loader.vue'
import { SectorId, SectorImage, sectorImages } from '@/components/image-loader'
import { CanvasDrawer } from '@/components/boulder/canvas'

export type MouseDownPayload = {
    event: MouseEvent
    scaleFactor: number
}

@Component({
    components: {
        CanvasDrawer,
        ImageLoader,
        customButton: Button
    }
})
export default class BoulderCanvas extends Vue {
    @Prop({ type: Array, default: () => [] }) holds!: Hold[]
    @Prop({ type: String }) sectorId!: SectorId
    @Prop({ type: Number, default: 0 }) heightOffset!: number

    scaleFactor = 1
    canvasContext: CanvasRenderingContext2D | null = null
    imagesLoaded = false

    images(): SectorImage[] {
        return sectorImages
    }

    onMouseDown(e: MouseEvent): void {
        this.$emit('mouse-down', {
            event: e,
            scaleFactor: this.scaleFactor
        })
    }

    get image(): HTMLImageElement {
        const image = this.selectImage()

        this.updateContainerDimensions()

        if (image) {
            this.scaleCanvas(image)
        }

        return image
    }

    private selectImage() {
        return document.getElementById(this.sectorId) as HTMLImageElement
    }

    get goodToDraw(): boolean {
        return this.imagesLoaded
    }

    private scaleCanvas(image: HTMLImageElement): void {
        const c = this.$refs['myCanvas'] as HTMLCanvasElement

        c.height = image.height * this.scaleFactor
        c.width = image.width * this.scaleFactor
    }

    updateContainerDimensions(): void {
        const canvasContainer = document.getElementById('canvasContainer') as HTMLElement

        const { height, width } = this.calcContainerDimensions()

        canvasContainer.style.maxHeight = (height - 100).toString() + 'px'
        canvasContainer.style.width = width.toString() + 'px'
    }

    private calcContainerDimensions() {
        const selector = this.$refs.selectorContainer as HTMLElement
        const { width } = selector.getBoundingClientRect()
        const style = window.getComputedStyle(selector)

        const containerHeight = window.innerHeight - this.heightOffset
        const containerWidth = width - 2 * parseInt(style.paddingLeft)

        return { height: containerHeight, width: containerWidth }
    }

    private setInitialScaleFactor() {
        const scrollbarWidth = 14
        const containerDimensions = this.calcContainerDimensions()
        const image = this.selectImage()

        if (!image) {
            return
        }

        const result = (containerDimensions.height - scrollbarWidth) / image.height

        // if scale on height results in container not filled, scale on width
        const effectiveContainerWidth = containerDimensions.width - scrollbarWidth
        if (image.width * result < effectiveContainerWidth) {
            this.scaleFactor = effectiveContainerWidth / image.width

            return
        }

        this.scaleFactor = result
    }

    onImagesLoaded(): void {
        this.setInitialScaleFactor()
        this.imagesLoaded = true
    }

    mounted(): void {
        const canvas = this.$refs['myCanvas'] as HTMLCanvasElement
        if (canvas) {
            this.canvasContext = canvas.getContext('2d')
        }
    }
}
