








































import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import Button from '@/infrastructure/ui/button.vue'
import { hold, HOLD, Hold, HoldType } from '@/domain/boulder/hold'
import ImageLoader from '@/components/image-loader/image-loader.vue'
import { CanvasDrawer } from '@/components/boulder/canvas'
import BoulderCanvas, { MouseDownPayload } from '@/components/boulder/canvas/boulder-canvas.vue'
import { options } from '@/infrastructure/plugins/vuetify'
import { RADIUS } from '@/components/boulder/canvas/canvas-drawer.vue'

const HOLD_TOGGLE = {
    [HOLD.START]: 0,
    [HOLD.BETWEEN]: 1,
    [HOLD.TOP]: 2
}

@Component({
    components: {
        BoulderCanvas,
        CanvasDrawer,
        ImageLoader,
        customButton: Button
    }
})
export default class HoldSelector extends Vue {
    @VModel({ type: Array, default: () => [] }) holds!: Hold[]
    @Prop({ type: String }) sectorId!: string
    @Prop({ type: Array, default: () => [] }) errors!: string[]

    currentType: HoldType = HOLD.START
    holdToggle = 0

    onMouseDown({ event, scaleFactor }: MouseDownPayload): void {
        const x = event.offsetX / scaleFactor
        const y = event.offsetY / scaleFactor
        const holdToBeRemoved = this.checkForHoldRemoval(x, y)

        if (holdToBeRemoved !== null) {
            const { x, y, type } = holdToBeRemoved
            this.holds = this.holds.filter((hold) => {
                return x !== hold.x || y !== hold.y
            })

            this.setHoldType(type)
            this.holdToggle = HOLD_TOGGLE[type]

            return
        }

        this.holds = [
            ...this.holds,
            {
                x: x,
                y: y,
                type: this.currentType
            }
        ]

        this.updateHoldType()
    }

    setHoldType(type: HoldType): void {
        this.currentType = type
    }

    holdType(type: HoldType): string {
        return hold.toText(type)
    }

    holdColor(type: HoldType): string {
        return options.theme.themes.light[type]
    }

    private checkForHoldRemoval(x: number, y: number): null | Hold {
        return (
            this.holds.find((hold) => {
                return Math.sqrt((hold.x - x) ** 2 + (hold.y - y) ** 2) < RADIUS * 0.75
            }) || null
        )
    }

    private updateHoldType() {
        if (this.currentType !== HOLD.START) {
            return
        }

        if (this.holds.filter((hold) => hold.type === HOLD.START).length >= 1) {
            this.setHoldType(HOLD.BETWEEN)
            this.holdToggle = HOLD_TOGGLE[HOLD.BETWEEN]
        }
    }
}
