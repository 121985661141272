
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Hold } from '@/domain/boulder/hold'

export const RADIUS = 35

@Component({})
export default class CanvasDrawer extends Vue {
    @Prop({ required: true })
    context!: CanvasRenderingContext2D
    @Prop() image!: HTMLImageElement
    @Prop({ type: Array, required: true }) holds!: Hold[]
    @Prop({ type: Number, required: true }) scaleFactor!: number

    @Watch('image', { deep: true, immediate: true })
    drawImage(): void {
        if (this.image) {
            this.context.drawImage(
                this.image,
                0,
                0,
                this.image.width * this.scaleFactor,
                this.image.height * this.scaleFactor
            )
        }
    }

    markHolds(): void {
        this.holds.forEach((hold) =>
            this.drawMarker(
                hold.x * this.scaleFactor,
                hold.y * this.scaleFactor,
                RADIUS * this.scaleFactor,
                this.$vuetify.theme.themes.light[hold.type] as string
            )
        )
    }

    @Watch('holds', { deep: true, immediate: true })
    @Watch('scaleFactor')
    redraw(): void {
        this.drawImage()
        this.markHolds()
    }

    drawMarker(x: number, y: number, radius: number, color: string): void {
        this.drawCircle(x, y, radius, color)
        this.drawCrosshair(x, y, radius, color)
    }

    drawCircle(x: number, y: number, radius: number, color: string): void {
        const ctx = this.context
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.lineWidth = 2.7
        ctx.arc(x, y, radius, 0, 360)
        ctx.stroke()
        ctx.closePath()
    }

    drawCrosshair(x: number, y: number, radius: number, color: string): void {
        const ctx = this.context
        // remove aliasing
        x = Math.floor(x) + 0.9
        y = Math.floor(y) + 0.9
        ctx.lineWidth = 2

        const startDist = radius / Math.sqrt(2)
        const endDist = radius / 2.5
        ctx.moveTo(x - startDist, y + startDist)
        ctx.lineTo(x - endDist, y + endDist)

        ctx.moveTo(x + startDist, y + startDist)
        ctx.lineTo(x + endDist, y + endDist)

        ctx.moveTo(x + startDist, y - startDist)
        ctx.lineTo(x + endDist, y - endDist)

        ctx.moveTo(x - startDist, y - startDist)
        ctx.lineTo(x - endDist, y - endDist)

        ctx.strokeStyle = color

        ctx.stroke()
    }
}
